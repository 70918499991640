import { Box, IconButton } from '@mui/material';
import { useState } from 'react'; 
import { FaArrowCircleUp } from 'react-icons/fa'; 
  
const ScrollToTop = () =>{ 
    const [visible, setVisible] = useState(false) 

    const toggleVisible = () => { 
        const scrolled = document.documentElement.scrollTop;

        if (scrolled > 300){ 
            setVisible(true) 
        }  
        else if (scrolled <= 300){ 
            setVisible(false) 
        }
    }; 

    const scrollToTop = () =>{ 
        window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
        }); 
    }; 

    window.addEventListener('scroll', toggleVisible); 
  
    return ( 
        <Box position={`fixed`} sx={
            {
                bottom: 10,
                right: 10,
                zIndex: 5,
                opacity: visible ? 1 : 0,
                transition: 'opacity .5s ease',
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'block'
                }
            }
        }>
            <IconButton onClick={scrollToTop} sx={
                {
                    display: visible ? 'block' : 'none'
                }
            }>
                <FaArrowCircleUp color='#ffe160' size={30} />
            </IconButton>
        </Box>
    )
} 
  
export default ScrollToTop; 