import { Grid } from "@mui/material";

import Sports_01 from './../../assets/images/games/sports/01.png';
import Sports_02 from './../../assets/images/games/sports/02.png';
import Sports_03 from './../../assets/images/games/sports/03.png';
import Sports_04 from './../../assets/images/games/sports/04.png';
import Sports_05 from './../../assets/images/games/sports/05.png';
import Sports_06 from './../../assets/images/games/sports/06.png';

const Sports = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Sports_01} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Sports_02} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Sports_03} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Sports_04} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Sports_05} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Sports_06} alt="" style={{ width: '100%' }} />
            </Grid>
        </Grid>
    )
}

export default Sports;