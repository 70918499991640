import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie";
import { loginFormType, createMemberType } from './../types';

export class ApiService {
    private baseURL: string | undefined;
    private instance: AxiosInstance;

    constructor(timeout?: number | undefined | null) {
        this.baseURL = process.env.REACT_APP_API_URL;
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: timeout || 120 * 1000,
            timeoutErrorMessage: "Time out!",
        });
    }

    getBaseURL = (): string | undefined => {
        return this.baseURL;
    }

    get = async (path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.get(path, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    post = async (path: string, body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.post(path, body, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    check_access_token = async(access_token: string): Promise<AxiosResponse> => {
        return this.instance.post('/member/checkaccesstoken', {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    login = async(body: loginFormType, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.post('/member/login', body, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    register = async (createMember: createMemberType): Promise<AxiosResponse> => {
        const cookies = new Cookies(null, {path: '/'});

        return this.instance.post('/member/register', {
            password: createMember.password,
            phone: createMember.phone,
            first_name: createMember.first_name,
            last_name: createMember.last_name,
            line: createMember.line,
            know_at: createMember.know_at,
            bank: createMember.bank,
            bank_number: createMember.bank_number,
            referrer: cookies.get('referrer') ? cookies.get('referrer') : null
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberData = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get('/member/get/data', {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getCurrentCredit = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get('/member/get/currentcredit', {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    requestPay = async (access_token: string, amount: string): Promise<AxiosResponse> => {
        return this.instance.post('/ggpayment/requestPay', {
            amount: amount
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    requestPayout = async (access_token: string, amount: string): Promise<AxiosResponse> => {
        return this.instance.post('/member/withdraw', {
            amount: amount
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    checkDepositTransaction = async (access_token: string, transaction_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/member/checkDepositTransaction/${transaction_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getAccountActive = async (): Promise<AxiosResponse> => {
        return this.instance.get(`/scb/get/account`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getListPromotion = async (): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/promotion`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getPromotion = async (access_token: string, promotion_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/member/get/promotion`, {
            promotion_id: promotion_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }
}