import { useState } from "react";
import { Box, Typography, IconButton, Grid, Divider, Button, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { listPromotionsType } from "../../types";
import default_new_member from '../../assets/images/promotions/new_member.jpg';
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

interface PropsType {
    promotion_detail: listPromotionsType | null;
    setShowPromotion: (state: listPromotionsType | null) => void;
}

const PromotionPopup = ({ promotion_detail, setShowPromotion }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);

    const getPromotion = async () => {
        if(promotion_detail === null || loading)
        {
            return;
        }

        if(!cookies.get('access_token'))
        {
            setShowPromotion(null);
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                html: `กรุณาเข้าสู่ระบบก่อนค่ะ`
            });
        }

        setLoading(true);
        
        try {
            const service = new ApiService();
            const getPromotion = await service.getPromotion(cookies.get('access_token'), promotion_detail.id);

            if(getPromotion.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'รับโปรโมชั่นเรียบร้อยแล้วค่ะ'
                });
                setShowPromotion(null);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    html: `รับโปรโมชั่นไม่สำเร็จ<br/>ข้อผิดพลาด: ${getPromotion.data.message || getPromotion.data.err}`
                });
                setShowPromotion(null);
            }
        } catch (err) {
            console.log(err);
        }

        setLoading(false);
    }

    return promotion_detail !== null ? (
        <>
            <Box width={`100%`} height={`100%`} sx={
                {
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                    display: promotion_detail !== null ? 'block' : 'none',
                    zIndex: 9999
                }
            }></Box>
            <Box sx={
                {
                    position: `fixed`,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: `100%`,
                    height: `100dvh`,
                    backgroundColor: 'rgba(0, 0, 0, .5)',
                    zIndex: 10000,
                    display: promotion_detail !== null ? 'block' : 'none'
                }
            }>
                <Box height={`100%`} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} px={3}>
                    <Box sx={
                        {
                            background: 'white',
                            height: 'auto',
                            maxHeight: '80vh',
                            maxWidth: '750px',
                            width: '100%',
                            overflowY: 'auto',
                            p: 3,
                            borderRadius: 2
                        }
                    }>
                        <Box display={`flex`} justifyContent={"space-between"}>
                            <Typography fontSize={24} color={`black`} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                โปรโมชั่น: { promotion_detail.name }
                            </Typography>
                            <IconButton onClick={() => {
                                if(!loading)
                                {
                                    setShowPromotion(null);
                                }
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                <img src={promotion_detail.poster_image === 'default_new_member' ? default_new_member : promotion_detail.poster_image} alt={`promotion_${promotion_detail.name}`} style={{ maxWidth: '100%', maxHeight: '450px' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color={`black`}>
                                    <div dangerouslySetInnerHTML={{ __html: promotion_detail.html }} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth sx={
                                    {
                                        py: 2,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                            color: 'black',
                                        }
                                    }
                                } onClick={getPromotion}>
                                    <Typography fontSize={16} fontWeight={600}>
                                        {
                                            loading ? <CircularProgress size={24} sx={
                                                {
                                                    color: 'white'
                                                }
                                            } /> : "รับโปรโมชั่น"
                                        }
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    ) : <></>
}

export default PromotionPopup;