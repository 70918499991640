import { Grid } from "@mui/material";

import Slot_01 from './../../assets/images/games/slot/01.png';
import Slot_02 from './../../assets/images/games/slot/02.png';
import Slot_03 from './../../assets/images/games/slot/03.png';
import Slot_04 from './../../assets/images/games/slot/04.png';
import Slot_05 from './../../assets/images/games/slot/05.png';
import Slot_06 from './../../assets/images/games/slot/06.png';

const Slot = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Slot_01} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Slot_02} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Slot_03} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Slot_04} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Slot_05} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Slot_06} alt="" style={{ width: '100%' }} />
            </Grid>
        </Grid>
    )
}

export default Slot;