import { Grid, Box, Typography, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';

const Highlight = styled('span')({
    fontWeight: 'bold'
});

const HomeCasinoContent = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box width={`100%`} sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, .5)' }}>
                    <Box sx={
                        {
                            backgroundColor: '#5e0000',
                            p: 2
                        }
                    }>
                        <Typography fontSize={24} align="center" color={'#ffee65'} sx={{ fontWeight: 'bold' }}>
                            เล่น CASINO
                        </Typography>
                    </Box>
                    <Box sx={
                        {
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                            p: 2
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography fontSize={20} ml={`40px`}>
                                    <Highlight>Ufa111Casino รวมเกมคาสิโนที่แตกง่ายที่สุด แจกเครดิตสมาชิกใหม่</Highlight>
                                </Typography>
                                <Typography fontSize={18}>
                                    <Highlight>Ufa111Casino</Highlight> เป็นแหล่งรวมเกมคาสิโนที่มีชื่อเสียงในเรื่องของการแจกแจงรางวัลที่แตกง่ายที่สุด เนื่องจากเรามีเกมสล็อตที่มีอัตราการจ่ายสูงที่มาพร้อมกับโอกาสในการชนะที่มากกว่าเดิม ด้วยระบบที่ทันสมัยและมีการพัฒนาอย่างต่อเนื่อง ทำให้เกมทั้งหมดที่นำเสนอมีความเสถียรและให้ประสบการณ์การเล่นที่ราบรื่น <Highlight>Ufa111Casino</Highlight> ทำให้ผู้เล่นสามารถเริ่มต้นการเล่นได้โดยไม่ต้องลงทุนมากและมีโอกาสสร้างกำไรได้จริง
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomeCasinoContent;