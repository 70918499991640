import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { memberDataType } from "../../types";

import { Box, Grid, Typography, CircularProgress, Button, Divider } from "@mui/material";

const MemberInfo = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [memberData, setMemberData] = useState<memberDataType>({
        phone: null,
        firstname: null,
        lastname: null,
        ufa_username: null,
        ufa_password: null,
        current_credit_limit: null,
        bank: null,
        bank_number: null
    });

    useEffect(() => {
        getMemberData();
    }, []);

    const getMemberData = async () => {
        try {
            const service = new ApiService();
            const memberData = await service.getMemberData(cookies.get('access_token'));

            if(memberData.data.status_code === 200 && memberData.data.data)
            {
                setMemberData(memberData.data.data);
                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <Box my={3}>
            <Box sx={
                {
                    backgroundColor: '#181818',
                    p: 3,
                    borderRadius: '5px 5px 0px 0px'
                }
            }>
                <Grid container spacing={2}>
                    <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                        <Typography>
                            ยินดีต้อนรับคุณ&nbsp;
                        </Typography>
                        <Typography color={`#f4ce5c`}>
                            {
                                memberData.firstname
                            }
                            &nbsp;
                            {
                                memberData.lastname
                            }
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="center">
                            ยอดเครดิตคงเหลือ
                        </Typography>
                        <Typography align="center" color={"#f4ce5c"} fontSize={60}>
                            ฿{
                                parseFloat(String(memberData.current_credit_limit)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            }
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Divider sx={{ borderColor: 'red' }}>
                            ข้อมูลบัญชี
                        </Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                <Typography>
                                    ยูสเซอร์เนมสำหรับเข้า UFA:&nbsp;
                                </Typography>
                                <Typography color={`#f4ce5c`}>
                                    {memberData.ufa_username}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                <Typography>
                                    รหัสผ่านสำหรับเข้า UFA:&nbsp;
                                </Typography>
                                <Typography color={`#f4ce5c`}>
                                    {memberData.ufa_password}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                <Typography>
                                    เบอร์โทรศัพท์:&nbsp;
                                </Typography>
                                <Typography color={`#f4ce5c`}>
                                    {memberData.phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                <Typography>
                                    ชื่อ - นามสกุล:&nbsp;
                                </Typography>
                                <Typography color={`#f4ce5c`}>
                                    {memberData.firstname}&nbsp;{memberData.lastname}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                <Typography>
                                    หมายเลขบัญชี:&nbsp;
                                </Typography>
                                <Typography color={`#f4ce5c`}>
                                    {memberData.bank_number}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                                <Typography>
                                    ธนาคาร:&nbsp;
                                </Typography>
                                <Typography color={`#f4ce5c`}>
                                    {memberData.bank}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ my: 3 }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button sx={
                                    {
                                        my: .5,
                                        borderRadius: 15,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        maxHeight: 50,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                        }
                                    }
                                } size="small" fullWidth onClick={() => navigate('/deposit')}>
                                    ฝากเงิน
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button sx={
                                    {
                                        my: .5,
                                        borderRadius: 15,
                                        backgroundColor: 'transparent',
                                        border: 'solid 1px #f4ce5c',
                                        color: 'white',
                                        // minHeight: 30,
                                        // maxHeight: 50,
                                        // height: `100%`,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                            color: 'black'
                                        }
                                    }
                                } size="small" fullWidth onClick={() => navigate('/withdraw')}>
                                    ถอนเงิน
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ my: 3 }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        maxHeight: 50,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                        }
                                    }
                                } size="small" fullWidth onClick={() => navigate('/member')}>
                                    ย้อนกลับ
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#f4ce5c'
                    }
                } />
            </Box>
        </Box>
    )
}

export default MemberInfo;