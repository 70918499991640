import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { memberDataType } from "../../types";

import { Box, Grid, Typography, CircularProgress, Button } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FeaturedPlayListRoundedIcon from '@mui/icons-material/FeaturedPlayListRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';

import { useMediaQuery } from 'react-responsive'

const MemberMain = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1920px)'
    });

    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [memberData, setMemberData] = useState<memberDataType>({
        phone: null,
        firstname: null,
        lastname: null,
        ufa_username: null,
        ufa_password: null,
        current_credit_limit: null,
        bank: null,
        bank_number: null
    });

    useEffect(() => {
        getMemberData();
    }, []);

    const getMemberData = async () => {
        try {
            const service = new ApiService();
            const memberData = await service.getMemberData(cookies.get('access_token'));

            if(memberData.data.status_code === 200 && memberData.data.data)
            {
                setMemberData(memberData.data.data);
                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <Box my={3}>
            <Box sx={
                {
                    backgroundColor: '#181818',
                    p: 3,
                    borderRadius: '5px 5px 0px 0px'
                }
            }>
                <Grid container spacing={2}>
                    <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                        <Typography>
                            ยินดีต้อนรับคุณ&nbsp;
                        </Typography>
                        <Typography color={`#f4ce5c`}>
                            {
                                memberData.firstname
                            }
                            &nbsp;
                            {
                                memberData.lastname
                            }
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography align="center">
                            ยอดเครดิตคงเหลือ
                        </Typography>
                        <Typography align="center" color={"#f4ce5c"} fontSize={60}>
                            ฿{
                                parseFloat(String(memberData.current_credit_limit)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            }
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Button sx={
                            {
                                my: .5,
                                borderRadius: 15,
                                px: 2,
                                backgroundColor: '#f4ce5c',
                                color: 'black',
                                // minHeight: 30,
                                // maxHeight: 50,
                                // height: `100%`,
                                '&:hover': {
                                    backgroundColor: '#e2be55',
                                }
                            }
                        } size="small" fullWidth onClick={() => navigate('/deposit')}>
                            <Typography fontSize={30}>
                                ฝากเงิน
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Button sx={
                            {
                                my: .5,
                                borderRadius: 15,
                                px: 2,
                                backgroundColor: 'transparent',
                                border: 'solid 1px #f4ce5c',
                                color: 'white',
                                // minHeight: 30,
                                // maxHeight: 50,
                                // height: `100%`,
                                '&:hover': {
                                    backgroundColor: '#e2be55',
                                    color: 'black'
                                }
                            }
                        } size="small" fullWidth onClick={() => navigate('/withdraw')}>
                            <Typography fontSize={30}>
                                ถอนเงิน
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={
                {
                    backgroundColor: '#646464',
                    p: 3,
                    borderRadius: '0px 0px 5px 5px',
                    borderTop: 'solid 1px #f4ce5c'
                }
            }>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <form method="post" action={`${isDesktopOrLaptop ? "https://lion777.apple855.com/Default8.aspx?lang=EN-GB" : "https://lion777.apple855.com/HomeSmart.aspx?lang=EN-GB"}`}>
                            {
                                isDesktopOrLaptop ? <>
                                    <input name="__EVENTTARGET" id="__EVENTTARGET" type="hidden" value="btnLogin" />
                                    <input name="__VIEWSTATE" id="__VIEWSTATE" type="hidden" value="/wEPDwUKLTM5ODkwMjE4NA8WAh4IUmVmZXJyZXIFE2h0dHBzOi8vdWZhMTExLmNvbS8WAmYPZBYGAgIPFgIeC3BsYWNlaG9sZGVyBR7guIrguLfguYjguK3guJzguLnguYnguYPguIrguYlkAgMPFgIfAQUY4Lij4Lir4Lix4Liq4Lic4LmI4Liy4LiZZAIEDw8WAh4EVGV4dAUh4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaZGQYAQUeX19Db250cm9sc1JlcXVpcmVQb3N0QmFja0tleV9fFgEFCWJ0bkxvZ2luMnMeii9lOZlDylIlusHF8exxbbei" />
                                    <input name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" type="hidden" value="BE6BC141" />
                                    <input name="lstLang" id="lstLang" type="hidden" value="Default8.aspx?lang=EN-GB" />
                                    <input name="txtUserName" id="txtUserName" type="hidden" value={`${memberData.ufa_username}`} />
                                    <input name="password" id="password" type="hidden" value={`${memberData.ufa_password}`} />
                                </> : <>
                                    <input type="hidden" name="__EVENTTARGET" value="btnSignIn" />
                                    <input type="hidden" name="__EVENTVALIDATION" value="/wEdAATN+H29TbTr6+TKWhOmoINkY3plgk0YBAefRz3MyBlTcO4sciJO3Hoc68xTFtZGQEivn9vBjVd9fs+uQ2w6sTEudYvEltaZSTEce778Y/NsfwmCylU=" />
                                    <input type="hidden" name="__VIEWSTATE" value="/wEPDwUJNzQ1NzI2OTI1D2QWAmYPZBYEAgEPFgIeC3BsYWNlaG9sZGVyBR7guIrguLfguYjguK3guJzguLnguYnguYPguIrguYlkAgIPFgIfAAUY4Lij4Lir4Lix4Liq4Lic4LmI4Liy4LiZZGTBvshltVIXgZaAPV2VAju1hl/69A==" />
                                    <input type="hidden" name="__VIEWSTATEGENERATOR" value="DF40E925" />
                                    <input type="hidden" name="lstLang" value="Default8.aspx?lang=EN-GB" />
                                    <input type="hidden" name="txtUserName" value={`${memberData.ufa_username}`} />
                                    <input type="hidden" name="password" value={`${memberData.ufa_password}`} />
                                </>
                            }
                            <Button type="submit" sx={
                                {
                                    my: .5,
                                    borderRadius: 5,
                                    px: 2,
                                    backgroundColor: '#f4ce5c',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: '#e2be55',
                                    }
                                }
                            } fullWidth>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography sx={{ p: 0, m: 0 }}>
                                            <PlayArrowIcon fontSize={`large`} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={30} sx={{ p: 0, m: 0 }}>
                                            เข้าเล่น UFABET
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </form>
                        <form method="post" action={`${isDesktopOrLaptop ? "https://www.sun34444.com/Default8.aspx?lang=EN-GB" : "https://www.sun34444.com/HomeSmart.aspx?lang=EN-GB"}`}>
                            {
                                isDesktopOrLaptop ? <>
                                    <input name="__EVENTTARGET" id="__EVENTTARGET" type="hidden" value="btnLogin" />
                                    <input name="__VIEWSTATE" id="__VIEWSTATE" type="hidden" value="/wEPDwUKLTM5ODkwMjE4NA8WAh4IUmVmZXJyZXIFE2h0dHBzOi8vdWZhMTExLmNvbS8WAmYPZBYGAgIPFgIeC3BsYWNlaG9sZGVyBR7guIrguLfguYjguK3guJzguLnguYnguYPguIrguYlkAgMPFgIfAQUY4Lij4Lir4Lix4Liq4Lic4LmI4Liy4LiZZAIEDw8WAh4EVGV4dAUh4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaZGQYAQUeX19Db250cm9sc1JlcXVpcmVQb3N0QmFja0tleV9fFgEFCWJ0bkxvZ2luMnMeii9lOZlDylIlusHF8exxbbei" />
                                    <input name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" type="hidden" value="BE6BC141" />
                                    <input name="lstLang" id="lstLang" type="hidden" value="Default8.aspx?lang=EN-GB" />
                                    <input name="txtUserName" id="txtUserName" type="hidden" value={`${memberData.ufa_username}`} />
                                    <input name="password" id="password" type="hidden" value={`${memberData.ufa_password}`} />
                                </> : <>
                                    <input type="hidden" name="__EVENTTARGET" value="btnSignIn" />
                                    <input type="hidden" name="__EVENTVALIDATION" value="/wEdAATN+H29TbTr6+TKWhOmoINkY3plgk0YBAefRz3MyBlTcO4sciJO3Hoc68xTFtZGQEivn9vBjVd9fs+uQ2w6sTEudYvEltaZSTEce778Y/NsfwmCylU=" />
                                    <input type="hidden" name="__VIEWSTATE" value="/wEPDwUJNzQ1NzI2OTI1D2QWAmYPZBYEAgEPFgIeC3BsYWNlaG9sZGVyBR7guIrguLfguYjguK3guJzguLnguYnguYPguIrguYlkAgIPFgIfAAUY4Lij4Lir4Lix4Liq4Lic4LmI4Liy4LiZZGTBvshltVIXgZaAPV2VAju1hl/69A==" />
                                    <input type="hidden" name="__VIEWSTATEGENERATOR" value="DF40E925" />
                                    <input type="hidden" name="lstLang" value="Default8.aspx?lang=EN-GB" />
                                    <input type="hidden" name="txtUserName" value={`${memberData.ufa_username}`} />
                                    <input type="hidden" name="password" value={`${memberData.ufa_password}`} />
                                </>
                            }
                            <Button type="submit" sx={
                                {
                                    my: .5,
                                    borderRadius: 5,
                                    px: 2,
                                    backgroundColor: '#f4ce5c',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: '#e2be55',
                                    }
                                }
                            } fullWidth>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography sx={{ p: 0, m: 0 }}>
                                            <PlayArrowIcon fontSize={`large`} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={30} sx={{ p: 0, m: 0 }}>
                                            เข้าเล่นสำรอง 1
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </form>
                        <form method="post" action={`${isDesktopOrLaptop ? "https://www.usa2468.com/Default8.aspx?lang=EN-GB" : "https://www.usa2468.com/HomeSmart.aspx?lang=EN-GB"}`}>
                            {
                                isDesktopOrLaptop ? <>
                                    <input name="__EVENTTARGET" id="__EVENTTARGET" type="hidden" value="btnLogin" />
                                    <input name="__VIEWSTATE" id="__VIEWSTATE" type="hidden" value="/wEPDwUKLTM5ODkwMjE4NA8WAh4IUmVmZXJyZXIFE2h0dHBzOi8vdWZhMTExLmNvbS8WAmYPZBYGAgIPFgIeC3BsYWNlaG9sZGVyBR7guIrguLfguYjguK3guJzguLnguYnguYPguIrguYlkAgMPFgIfAQUY4Lij4Lir4Lix4Liq4Lic4LmI4Liy4LiZZAIEDw8WAh4EVGV4dAUh4LmA4LiC4LmJ4Liy4Liq4Li54LmI4Lij4Liw4Lia4LiaZGQYAQUeX19Db250cm9sc1JlcXVpcmVQb3N0QmFja0tleV9fFgEFCWJ0bkxvZ2luMnMeii9lOZlDylIlusHF8exxbbei" />
                                    <input name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" type="hidden" value="BE6BC141" />
                                    <input name="lstLang" id="lstLang" type="hidden" value="Default8.aspx?lang=EN-GB" />
                                    <input name="txtUserName" id="txtUserName" type="hidden" value={`${memberData.ufa_username}`} />
                                    <input name="password" id="password" type="hidden" value={`${memberData.ufa_password}`} />
                                </> : <>
                                    <input type="hidden" name="__EVENTTARGET" value="btnSignIn" />
                                    <input type="hidden" name="__EVENTVALIDATION" value="/wEdAATN+H29TbTr6+TKWhOmoINkY3plgk0YBAefRz3MyBlTcO4sciJO3Hoc68xTFtZGQEivn9vBjVd9fs+uQ2w6sTEudYvEltaZSTEce778Y/NsfwmCylU=" />
                                    <input type="hidden" name="__VIEWSTATE" value="/wEPDwUJNzQ1NzI2OTI1D2QWAmYPZBYEAgEPFgIeC3BsYWNlaG9sZGVyBR7guIrguLfguYjguK3guJzguLnguYnguYPguIrguYlkAgIPFgIfAAUY4Lij4Lir4Lix4Liq4Lic4LmI4Liy4LiZZGTBvshltVIXgZaAPV2VAju1hl/69A==" />
                                    <input type="hidden" name="__VIEWSTATEGENERATOR" value="DF40E925" />
                                    <input type="hidden" name="lstLang" value="Default8.aspx?lang=EN-GB" />
                                    <input type="hidden" name="txtUserName" value={`${memberData.ufa_username}`} />
                                    <input type="hidden" name="password" value={`${memberData.ufa_password}`} />
                                </>
                            }
                            <Button type="submit" sx={
                                {
                                    my: .5,
                                    borderRadius: 5,
                                    px: 2,
                                    backgroundColor: '#f4ce5c',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: '#e2be55',
                                    }
                                }
                            } fullWidth>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography sx={{ p: 0, m: 0 }}>
                                            <PlayArrowIcon fontSize={`large`} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontSize={30} sx={{ p: 0, m: 0 }}>
                                            เข้าเล่นสำรอง 2
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                        </form>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Button sx={
                                    {
                                        mt: .5,
                                        borderRadius: 5,
                                        px: 2,
                                        backgroundColor: '#181818',
                                        color: 'white',
                                        height: `100%`,
                                        '&:hover': {
                                            backgroundColor: '#000000',
                                        }
                                    }
                                } fullWidth onClick={() => navigate('/memberinfo')}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography sx={{ p: 0, m: 0 }}>
                                                <FeaturedPlayListRoundedIcon fontSize={`large`} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={
                                                {
                                                    p: 0,
                                                    m: 0,
                                                    fontSize: {
                                                        xs: 25,
                                                        sm: 25,
                                                        md: 30,
                                                        lg: 30
                                                    }
                                                }
                                            }>
                                                ข้อมูลบัญชี
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button sx={
                                    {
                                        mt: .5,
                                        borderRadius: 5,
                                        px: 2,
                                        backgroundColor: '#181818',
                                        color: 'white',
                                        height: `100%`,
                                        '&:hover': {
                                            backgroundColor: '#000000',
                                        }
                                    }
                                } fullWidth>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography sx={{ p: 0, m: 0 }}>
                                                <GroupsRoundedIcon fontSize={`large`} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={
                                                {
                                                    p: 0,
                                                    m: 0,
                                                    fontSize: {
                                                        xs: 25,
                                                        sm: 25,
                                                        md: 30,
                                                        lg: 30
                                                    }
                                                }
                                            }>
                                                แนะนำเพื่อน
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button sx={
                                    {
                                        borderRadius: 5,
                                        px: 2,
                                        backgroundColor: '#181818',
                                        color: 'white',
                                        height: `100%`,
                                        '&:hover': {
                                            backgroundColor: '#000000',
                                        }
                                    }
                                } fullWidth>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography sx={{ p: 0, m: 0 }}>
                                                <CurrencyExchangeRoundedIcon fontSize={`large`} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={
                                                {
                                                    p: 0,
                                                    m: 0,
                                                    fontSize: {
                                                        xs: 25,
                                                        sm: 25,
                                                        md: 30,
                                                        lg: 30
                                                    }
                                                }
                                            }>
                                                รับเงินคืน
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#f4ce5c'
                    }
                } />
            </Box>
        </Box>
    )
}

export default MemberMain;