import { Box, Typography, Grid } from "@mui/material";

import HeaderHome from "../Swiper/HeaderHome";
import HomeContent from "./HomeContent";
import MakeProfit from "./MakeProfit";
import HomeCasinoContent from "./HomeCasinoContent";
import HomeSlotContent from "./HomeSlotContent";
import HomeSlotAutoContent from "./HomeSlotAutoContent";
import GameHome from "../Swiper/GameHome";
import Sports from "../GameSixLayout/Sports";
import Casino from "../GameSixLayout/Casino";
import Slot from "../GameSixLayout/Slot";
import ShootFish from "../GameSixLayout/ShootFish";
import Lotto from "../GameSixLayout/Lotto";

const Home = () => {
    return (
        <>
            <Box py={3}>
                <HeaderHome />
            </Box>
            <Box>
                <Typography fontSize={24} color={`#ffee65`}>
                    เกมสล็อต 10 อันดับยอดนิยม
                </Typography>
                <GameHome />
            </Box>
            <Box my={3}>
                <Typography fontSize={24} color={`#ffee65`}>
                    ผู้ให้บริการกีฬา & อีสปอร์ส
                </Typography>
                <Sports />
            </Box>
            <HomeCasinoContent />
            <Box my={3}>
                <Typography fontSize={24} color={`#ffee65`}>
                    ผู้ให้บริการคาสิโน
                </Typography>
                <Casino />
            </Box>
            <HomeSlotContent />
            <Box my={3}>
                <Typography fontSize={24} color={`#ffee65`}>
                    ผู้ให้บริการสล็อต
                </Typography>
                <Slot />
            </Box>
            <HomeSlotAutoContent />
            <Box my={3}>
                <Typography fontSize={24} color={`#ffee65`}>
                    ผู้ให้บริการยิงปลา
                </Typography>
                <ShootFish />
            </Box>
            <Box my={3}>
                <Typography fontSize={24} color={`#ffee65`}>
                    ผู้ให้บริการแทงหวย & โป๊กเกอร์
                </Typography>
                <Lotto />
            </Box>
            <HomeContent />
            <MakeProfit />
        </>
    )
}

export default Home;