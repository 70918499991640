import { Box, Button, Container, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Logo from './../../assets/images/logo.png';
import Cookies from "universal-cookie";

const NavMenuDesktop = () => {
    const cookies = new Cookies(null, {path: '/'});
    const navigate = useNavigate();

    return (
        <Box width={`100%`} bgcolor={`#5e0000`} className="header-bottom" sx={
            {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                    lg: 'block'
                }
            }
        }>
            <Container sx={{ py: .5 }}>
                <Box display={`flex`} justifyContent={`space-between`}>
                    <Box display={`flex`}>
                        <Link to="/">
                            <img src={Logo} alt="" style={{ width: `auto`, maxHeight: 55 }} />
                        </Link>
                    </Box>
                    <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                        {
                            cookies.get('access_token') ? <>
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        maxHeight: 50,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                        }
                                    }
                                } size="small" onClick={() => navigate('/member')}>
                                    ทางเข้าเล่น
                                </Button>
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        maxHeight: 50,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                        }
                                    }
                                } size="small" onClick={() => navigate('/deposit')}>
                                    ฝากเงิน
                                </Button>
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        maxHeight: 50,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                        }
                                    }
                                } size="small" onClick={() => navigate('/promotions')}>
                                    โปรโมชั่น
                                </Button>
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'transparent',
                                        border: 'solid 1px #f4ce5c',
                                        color: 'white',
                                        maxHeight: 50,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                            color: 'black'
                                        }
                                    }
                                } size="small" onClick={() => navigate('/logout')}>
                                    ออกจากระบบ
                                </Button>
                            </> : <>
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        maxHeight: 50,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                        }
                                    }
                                } size="small" onClick={() => navigate('/login')}>
                                    เข้าสู่ระบบ
                                </Button>
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'transparent',
                                        border: 'solid 1px #f4ce5c',
                                        color: 'white',
                                        maxHeight: 50,
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                            color: 'black'
                                        }
                                    }
                                } size="small" onClick={() => navigate('/register')}>
                                    สมัครสมาชิก
                                </Button>
                            </>
                        }
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default NavMenuDesktop;