import NavMenuDesktop from "./NavMenuDesktop";
import NavMenuMobile from "./NavMenuMobile";

const Header = () => {
    return (
        <>
            <NavMenuDesktop />
            <NavMenuMobile />
        </>
    )
}

export default Header;