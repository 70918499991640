import { Grid, Box, Typography, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';

const Highlight = styled('span')({
    fontWeight: 'bold'
});

const MakeProfit = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box width={`100%`} mb={3} sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, .5)' }}>
                    <Box sx={
                        {
                            backgroundColor: '#5e0000',
                            p: 2
                        }
                    }>
                        <Typography fontSize={24} align="center" color={'#ffee65'} sx={{ fontWeight: 'bold' }}>
                            ข้อดีในการเล่นที่ เว็บตรงUfa111 เพื่อทำกำไร
                        </Typography>
                    </Box>
                    <Box sx={
                        {
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                            p: 2
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography fontSize={20}>
                                    การเล่นที่ <Highlight>เว็บตรงUfa111</Highlight> มีข้อดีหลายประการที่ทำให้ผู้เล่นสามารถทำกำไรได้อย่างมั่นคงและมีประสิทธิภาพได้ดังนี้
                                </Typography>
                                <ul>
                                    <li style={{ marginTop: 1 }}>
                                        <Typography fontSize={18}>
                                            <Highlight>ความปลอดภัยและความน่าเชื่อถือ: Ufa111เว็บตรง</Highlight> มีระบบความปลอดภัยที่ทันสมัยและมีการปกป้องข้อมูลส่วนตัวของผู้เล่นอย่างเข้มงวด ทำให้มั่นใจได้ว่าการเล่นและการทำธุรกรรมต่างๆ จะได้รับการปกป้องอย่างดี
                                        </Typography>
                                    </li>
                                    <li style={{ marginTop: 1 }}>
                                        <Typography fontSize={18}>
                                            <Highlight>ระบบฝาก-ถอนอัตโนมัติ:</Highlight> การทำธุรกรรมการเงินเป็นเรื่องง่ายและรวดเร็วด้วยระบบฝาก-ถอนอัตโนมัติ ผู้เล่นสามารถฝากเงินและถอนเงินได้ตลอด 24 ชั่วโมง โดยไม่ต้องรอนาน
                                        </Typography>
                                    </li>
                                    <li style={{ marginTop: 1 }}>
                                        <Typography fontSize={18}>
                                            <Highlight>โปรโมชั่นและโบนัสพิเศษ:</Highlight> เว็บตรง111Ufa มีโปรโมชั่นและโบนัสที่หลากหลายสำหรับสมาชิกใหม่และสมาชิกเก่า ทำให้ผู้เล่นมีโอกาสได้รับเครดิตเพิ่มเติมและเพิ่มโอกาสในการทำกำไร
                                        </Typography>
                                    </li>
                                    <li style={{ marginTop: 1 }}>
                                        <Typography fontSize={18}>
                                            <Highlight>การบริการลูกค้า:</Highlight> ทีมงานบริการลูกค้าของ 111Ufaเว็บตรง พร้อมให้ความช่วยเหลือตลอด 24 ชั่วโมง ไม่ว่าจะเป็นการแก้ไขปัญหาหรือการตอบข้อสงสัย ทำให้ผู้เล่นได้รับการดูแลอย่างมืออาชีพและใส่ใจ
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography fontSize={20} ml={`40px`}>
                                    <Highlight>สรุป ทางเข้าUfa111 เล่นง่ายรวยไวได้กำไรแน่นอน</Highlight>
                                </Typography>
                                <Typography fontSize={18}>
                                    <Highlight>ทางเข้าUfa111</Highlight> มอบประสบการณ์การเล่นคาสิโนออนไลน์ที่ง่ายและสะดวกสบาย ด้วยระบบที่เสถียรและรวดเร็ว ผู้เล่นสามารถเข้าถึงเกมบนเว็บของเราได้ทันที ซึ่งทุกเกมมีอัตราการจ่ายสูง พร้อมด้วยโปรโมชั่นและโบนัสพิเศษที่ช่วยเพิ่มโอกาสในการทำกำไร ทำให้การเล่นกับ <Highlight>Ufa111ทางเข้า</Highlight> เป็นการลงทุนที่คุ้มค่าและมีโอกาสรวยไวกว่าที่อื่นแน่นอน
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default MakeProfit;