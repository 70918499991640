import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { ApiService } from "./services/api.service";
import { Container, Box, CircularProgress } from "@mui/material";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import NavFooterMobile from "./components/Footer/NavFooterMobile";
import ScrollToTop from "./ScrollToTop";

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const cookies = new Cookies(null, { path: '/' });
    const [_loggedIn, setLoggedIn] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        const ref = document.referrer;
        if(!cookies.get('referrer') || cookies.get('referrer') === '')
        {
            cookies.set('referrer', ref);
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const pathname = location.pathname.split("/")[1];

                setLoaded(false);
                if(!cookies.get('access_token'))
                {
                    if(pathname === 'member')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'memberinfo')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'deposit')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'withdraw')
                    {
                        navigate('/login');
                    }

                    setLoggedIn(false);
                    setLoaded(true);
                    return;
                }
        
                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const checkToken = (await service.check_access_token(access_token)).data;

                if(checkToken.status_code !== 200)
                {
                    cookies.remove('access_token');
                    setLoggedIn(false);
                    
                    if(pathname === 'member')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'memberinfo')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'deposit')
                    {
                        navigate('/login');
                    }
                    else if(pathname === 'withdraw')
                    {
                        navigate('/login');
                    }
                }
                else if(checkToken.status_code === 200)
                {
                    setLoggedIn(true);
                }

                setLoaded(true);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [location]);

    return (
        loaded ? <>
            <Header />
            <Container>
                <Outlet/>
            </Container>
            <Footer />
            <NavFooterMobile />
            <ScrollToTop />
        </> : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#f4ce5c'
                    }
                } />
            </Box>
        </Box>
    )
}

export default App;