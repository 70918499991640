import { Grid } from "@mui/material";

import ShootFish_01 from './../../assets/images/games/shootfish/01.png';
import ShootFish_02 from './../../assets/images/games/shootfish/02.png';
import ShootFish_03 from './../../assets/images/games/shootfish/03.png';
import ShootFish_04 from './../../assets/images/games/shootfish/04.png';
import ShootFish_05 from './../../assets/images/games/shootfish/05.png';
import ShootFish_06 from './../../assets/images/games/shootfish/06.png';

const ShootFish = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={ShootFish_01} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={ShootFish_02} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={ShootFish_03} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={ShootFish_04} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={ShootFish_05} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={ShootFish_06} alt="" style={{ width: '100%' }} />
            </Grid>
        </Grid>
    )
}

export default ShootFish;