import Game_01 from './../../assets/images/games/01.png';
import Game_02 from './../../assets/images/games/02.png';
import Game_03 from './../../assets/images/games/03.png';
import Game_04 from './../../assets/images/games/04.png';
import Game_05 from './../../assets/images/games/05.png';
import Game_06 from './../../assets/images/games/06.png';
import Game_07 from './../../assets/images/games/07.png';
import Game_08 from './../../assets/images/games/08.png';
import Game_09 from './../../assets/images/games/09.png';
import Game_10 from './../../assets/images/games/10.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './GameHome.css';
import { Navigation } from 'swiper/modules';

import { useMediaQuery } from 'react-responsive'

const GameHome = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    });
    
    return (
        <Swiper
            slidesPerView={isDesktopOrLaptop ? 6 : 3}
            spaceBetween={5}
            navigation={true}
            modules={[Navigation]}
            className="top-10-game-swiper"
        >
            <SwiperSlide>
                <img src={Game_01} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_02} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_03} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_04} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_05} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_06} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_07} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_08} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_09} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={Game_10} alt="" />
            </SwiperSlide>
        </Swiper>
    )
}

export default GameHome;