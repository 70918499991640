import { Grid, Box, Typography, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';

const Highlight = styled('span')({
    fontWeight: 'bold'
});

const HomeSlotAutoContent = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box width={`100%`} sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, .5)' }}>
                    <Box sx={
                        {
                            backgroundColor: '#5e0000',
                            p: 2
                        }
                    }>
                        <Typography fontSize={24} align="center" color={'#ffee65'} sx={{ fontWeight: 'bold' }}>
                            เล่น SLOT AUTO
                        </Typography>
                    </Box>
                    <Box sx={
                        {
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                            p: 2
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography fontSize={20} ml={`40px`}>
                                    <Highlight>Slot Auto เว็บตรง Ufa111Slot เล่นง่ายเข้าสปินไวโดนใจแน่นอน</Highlight>
                                </Typography>
                                <Typography fontSize={18}>
                                    <Highlight>Slot Auto เว็บตรง Ufa111Slot</Highlight> เป็นเว็บที่ออกแบบมาเพื่อตอบโจทย์นักเล่นสล็อตทุกคน ด้วยระบบอัตโนมัติที่ทำให้การเล่นเป็นไปอย่างรวดเร็วและราบรื่น ผู้เล่นสามารถเข้าสู่เกมและเริ่มสปินได้ภายในไม่กี่วินาที ระบบที่เสถียรและการออกแบบอินเตอร์เฟซที่ใช้งานง่ายทำให้แม้แต่ผู้เล่นมือใหม่ก็สามารถเข้าใจและเริ่มเล่นได้ทันที โดยไม่ต้องผ่านขั้นตอนที่ยุ่งยาก ทำให้ผู้เล่นสามารถเลือกเล่นเกมที่ชื่นชอบได้ตามสไตล์ของตัวเอง
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomeSlotAutoContent;