import { Grid, Box, Typography, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';

const Highlight = styled('span')({
    fontWeight: 'bold'
});

const HomeContent = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box width={`100%`} mb={3} sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, .5)' }}>
                    <Box sx={
                        {
                            backgroundColor: '#5e0000',
                            p: 2
                        }
                    }>
                        <Typography fontSize={24} align="center" color={'#ffee65'} sx={{ fontWeight: 'bold' }}>
                            UFA111 ดียังไง
                        </Typography>
                    </Box>
                    <Box sx={
                        {
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                            p: 2
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                <Typography fontSize={20}>
                                    <Highlight style={{ marginLeft: '40px' }}>
                                        Ufa111 คาสิโนเว็บตรง ระบบ UFABET แท้เว็บใหญ่ใจถึงพึ่งได้แน่นอน
                                    </Highlight>
                                    <Highlight> Ufa111</Highlight> เป็นคาสิโนเว็บตรงที่มาพร้อมกับระบบ <Highlight>UFABET</Highlight> แท้ ซึ่งเป็นระบบที่ได้รับการยอมรับในวงการคาสิโนออนไลน์ของไทย ด้วยความเป็นเว็บใหญ่ที่มีความน่าเชื่อถือสูง ทำให้ผู้เล่นมั่นใจได้ว่าการเล่นเกมและการเดิมพันต่างๆ จะได้รับการดูแลอย่างเป็นธรรมแน่นอน ไม่เพียงแค่การเล่นที่ปลอดภัย แต่ยังมีการจัดการทางการเงินที่รวดเร็วและมีประสิทธิภาพ ทำให้การฝาก-ถอนเงินเป็นเรื่องง่ายและสะดวกสบาย
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={
                                {
                                    display: {
                                        xs: 'none',
                                        sm: 'none',
                                        md: 'flex',
                                        lg: 'flex'
                                    },
                                    justifyContent: `center`
                                }
                            }>
                                <Divider orientation="vertical" flexItem sx={{ borderColor: 'rgba(255, 255, 255, .5)', height: `100%` }} />
                            </Grid>
                            <Grid item xs={12} my={2} sx={
                                {
                                    display: {
                                        xs: 'block',
                                        sm: 'block',
                                        md: 'none',
                                        lg: 'none'
                                    }
                                }
                            }>
                                <Divider sx={{ borderColor: 'rgba(255, 255, 255, .5)', width: `100%` }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                <Typography fontSize={20}>
                                    <Highlight style={{ marginLeft: '40px' }}>
                                        Ufa111 คาสิโนเว็บตรง ระบบ UFABET แท้เว็บใหญ่ใจถึงพึ่งได้แน่นอน
                                    </Highlight>
                                    <Highlight> Ufa111</Highlight> เป็นคาสิโนเว็บตรงที่มาพร้อมกับระบบ <Highlight>UFABET</Highlight> แท้ ซึ่งเป็นระบบที่ได้รับการยอมรับในวงการคาสิโนออนไลน์ของไทย ด้วยความเป็นเว็บใหญ่ที่มีความน่าเชื่อถือสูง ทำให้ผู้เล่นมั่นใจได้ว่าการเล่นเกมและการเดิมพันต่างๆ จะได้รับการดูแลอย่างเป็นธรรมแน่นอน ไม่เพียงแค่การเล่นที่ปลอดภัย แต่ยังมีการจัดการทางการเงินที่รวดเร็วและมีประสิทธิภาพ ทำให้การฝาก-ถอนเงินเป็นเรื่องง่ายและสะดวกสบาย
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomeContent;