import { Grid } from "@mui/material";

import Lotto_01 from './../../assets/images/games/lotto/01.png';
import Lotto_02 from './../../assets/images/games/lotto/02.png';
import Lotto_03 from './../../assets/images/games/lotto/03.png';
import Lotto_04 from './../../assets/images/games/lotto/04.png';
import Lotto_05 from './../../assets/images/games/lotto/05.png';
import Lotto_06 from './../../assets/images/games/lotto/06.png';

const Lotto = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Lotto_01} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Lotto_02} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Lotto_03} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Lotto_04} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Lotto_05} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Lotto_06} alt="" style={{ width: '100%' }} />
            </Grid>
        </Grid>
    )
}

export default Lotto;