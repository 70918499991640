import { Container, Box, Typography, Grid } from "@mui/material";
import Logo from './../../assets/images/logo.png';
import BanksImage from './../../assets/images/bank/banks.webp';

const Footer = () => {
    return (
        <>
            <Box width={`100%`} bgcolor={`#5e0000`} py={3} sx={
                {
                    borderTop: 'solid 1px black'
                }
            }>
                <Container>
                    {/* <Box display={`flex`} justifyContent={`center`} mb={2}>
                        <img src={Logo} alt="" style={{ maxHeight: 100 }} />
                    </Box> */}
                    <Typography fontSize={20} color={`#ffee65`} align="center">
                        เกี่ยวกับเรา
                    </Typography>
                    <Typography fontSize={18} align={`center`}>
                        UFA111 เว็บคาสิโนออนไลน์ ครบวงจร เดิมพัน กีฬา บาคาร่า สล็อต หวย ได้หมด ฝากถอนด้วยระบบออโต้ เร็วทันใจ จ่ายหนักไม่มีบิด เว็บตรงที่น่าเชื่อถือมากที่สุด
                    </Typography>
                    <Typography fontSize={20} color={`#ffee65`} align="center" mt={2}>
                        UFABET Auto ฝาก-ถอน รวดเร็วทันใจ ใน 30 วินาที
                    </Typography>
                    <Typography align={`center`}>
                        UFA111.com ให้บริการฝากถอนด้วยระบบอัตโนมัติ ทำรายการได้ตลอด 24 ชม. คุณจึงมั่นใจได้ว่าทุกยอดธุรกรรมของคุณจะไม่ตกหล่นอย่างแน่นอน
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Box display={`flex`} alignContent={`center`} alignItems={`center`} mt={2} sx={
                                {
                                    justifyContent: {
                                        xs: 'center',
                                        sm: 'center',
                                        md: 'center',
                                        lg: 'flex-start'
                                    },
                                }
                            }>
                                <img src={Logo} alt="" style={{ maxHeight: 50 }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Box display={`flex`} alignContent={`center`} alignItems={`center`} mt={2} sx={
                                {
                                    justifyContent: {
                                        xs: 'center',
                                        sm: 'center',
                                        md: 'center',
                                        lg: 'flex-end'
                                    }
                                }
                            }>
                                <img src={BanksImage} alt="banks" style={{ maxHeight: 50 }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box width={`100%`} bgcolor={"#181818"}>
                <Container>
                    <Typography display={`flex`} fontSize={12} sx={
                        {
                            justifyContent: {
                                xs: 'center',
                                sm: 'center',
                                md: 'center',
                                lg: 'flex-end'
                            }
                        }
                    }>
                        Copyright 2024 &copy; UFA111 ALL RIGHT RESERVED.
                    </Typography>
                </Container>
            </Box>
        </>
    )
}

export default Footer;