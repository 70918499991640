import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import { Container, Box, Grid, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import SavingsIcon from '@mui/icons-material/Savings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AddCardIcon from '@mui/icons-material/AddCard';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

const NavFooterMobile = () => {
    const cookies = new Cookies(null, {path:'/'});
    const navigate = useNavigate();

    const href = (url: string) => {
        navigate(url);
        window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
        });
    }

    return (
        <Box sx={
            {
                display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'block',
                    lg: 'none'
                }
            }
        }>
            <Box sx={{ mb: 6.8 }}>
            </Box>
            <Box py={.5} sx={
                {
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 4,
                    backgroundColor: "#5e0000",
                    width: `100%`,
                    borderTop: 'solid 1px black',
                }
            }>
                <Container sx={{ px: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={2.4} display={`flex`} flexDirection={`column`} sx={{ cursor: 'pointer' }} onClick={() => href(cookies.get('access_token') ? "/deposit" : "/register")}>
                            <Typography align="center">
                                {
                                    cookies.get('access_token') ? <AddCardIcon fontSize="small" /> : <PersonIcon fontSize="small" />
                                }
                            </Typography>
                            <Typography align="center" fontSize="small">
                                {
                                    cookies.get('access_token') ? "ฝากเงิน" : "สมัครสมาชิก"
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4} display={`flex`} flexDirection={`column`} sx={{ cursor: 'pointer' }} onClick={() => href(cookies.get('access_token') ? "/withdraw" : "/")}>
                            <Typography align="center">
                                {
                                    cookies.get('access_token') ? <PointOfSaleIcon fontSize="small" /> : <CardGiftcardIcon fontSize="small" />
                                }
                            </Typography>
                            <Typography align="center" fontSize="small">
                                {
                                    cookies.get('access_token') ? "ถอนเงิน" : "โปรโมชั่น"
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4} display={`flex`} flexDirection={`column`} sx={{ cursor: 'pointer' }} onClick={() => href(cookies.get('access_token') ? "/member" : "/login")}>
                            <Typography align="center">
                                <VideogameAssetIcon fontSize="small" />
                            </Typography>
                            <Typography align="center" fontSize="small">
                                {
                                    cookies.get('access_token') ? "ทางเข้าเล่น" : "เข้าสู่ระบบ"
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4} display={`flex`} flexDirection={`column`} sx={{ cursor: 'pointer' }} onClick={() => href(cookies.get('access_token') ? "/promotions" : "/promotions")}>
                            <Typography align="center">
                                {
                                    cookies.get('access_token') ? <CardGiftcardIcon fontSize="small" /> : <SavingsIcon fontSize="small" />
                                }
                            </Typography>
                            <Typography align="center" fontSize="small">
                                {
                                    cookies.get('access_token') ? "โปรโมชั่น" : "โปรโมชั่น"
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4} display={`flex`} flexDirection={`column`} sx={{ cursor: 'pointer' }} onClick={() => href('/')}>
                            <Typography align="center">
                                <SupportAgentIcon fontSize="small" />
                            </Typography>
                            <Typography align="center" fontSize="small">
                                ติดต่อเรา
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

export default NavFooterMobile;