import { Grid } from "@mui/material";

import Casino_01 from './../../assets/images/games/casino/01.png';
import Casino_02 from './../../assets/images/games/casino/02.png';
import Casino_03 from './../../assets/images/games/casino/03.png';
import Casino_04 from './../../assets/images/games/casino/04.png';
import Casino_05 from './../../assets/images/games/casino/05.png';
import Casino_06 from './../../assets/images/games/casino/06.png';

const Casino = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Casino_01} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Casino_02} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Casino_03} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Casino_04} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Casino_05} alt="" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <img src={Casino_06} alt="" style={{ width: '100%' }} />
            </Grid>
        </Grid>
    )
}

export default Casino;