import { Grid, Box, Typography, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';

const Highlight = styled('span')({
    fontWeight: 'bold'
});

const HomeSlotContent = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box width={`100%`} sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, .5)' }}>
                    <Box sx={
                        {
                            backgroundColor: '#5e0000',
                            p: 2
                        }
                    }>
                        <Typography fontSize={24} align="center" color={'#ffee65'} sx={{ fontWeight: 'bold' }}>
                            เล่น SLOT
                        </Typography>
                    </Box>
                    <Box sx={
                        {
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                            p: 2
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography fontSize={20} ml={`40px`}>
                                    <Highlight>ปั่นสล็อตยังไงให้ได้เงิน ต้อง Ufa111สล็อต เท่านั้นตอบโจทย์แน่นอน</Highlight>
                                </Typography>
                                <Typography fontSize={18}>
                                    การปั่นสล็อตให้ได้เงินเป็นศิลปะที่ต้องอาศัยทั้งเทคนิคและความเข้าใจในเกม ซึ่ง <Highlight>Ufa111สล็อต</Highlight> ตอบโจทย์ผู้เล่นทุกคน ด้วยเกมสล็อตที่มีอัตราการจ่ายสูง ผู้เล่นควรเริ่มต้นด้วยการเลือกเกมที่มีความเหมาะสมกับงบประมาณและสไตล์การเล่นของตนเอง โดยการศึกษาตารางการจ่ายเงิน (Paytable) และฟีเจอร์พิเศษต่างๆ ภายในเกม การตั้งเป้าหมายการเล่นอย่างมีวินัยก็เป็นสิ่งสำคัญในการทำกำไรได้ดีขึ้นกว่าเดิม
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomeSlotContent;